<style>
.tag {
    font-size: 12px;
    margin: 3px;
    padding: 4px 7px 2px 7px;
    float: left;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
  color: black;

}
</style>
<template>
    <div class="tags">
                <!--
            Release details:
            r   Regular Release, always filled in for copy-paste reasons
            m   Mix Release
            2   2 Month Spanning Release

            Collection status:
            c   In Collection
            w   Wanted (because we don't do implicit or derived states(!))
            d   Release in Collection is Damaged
            i   Incomplete (missing parts such as jewelcase, booklet, etc)
            x   Contains Extra stuff such as a flyer of the merchant
        -->
      <template v-if="!show_description">
        <span v-for="(tag, index) in tags" :key="index">
            <span class="tag" v-if="tag === 'in_collection'">✅</span>
            <span class="tag" v-if="tag === 'wanted'">📦</span>
            <span class="tag" v-if="tag === 'exclusive_items'">⭐</span>
            <span class="tag" v-if="tag === 'incomplete'">🔎</span>
            <span class="tag" v-if="tag === 'damaged'">🔨</span>
            <span class="tag" v-if="tag === 'two'">2️⃣</span>
            <span class="tag" v-if="tag === 'misprint'">🤪</span>
        </span>
      </template>
      <template v-else>
        <span v-for="(tag, index) in tags" :key="index">
            <span class="tag" v-if="tag === 'in_collection'">✅ In Collection</span>
            <span class="tag" v-if="tag === 'wanted'">📦 Wanted</span>
            <span class="tag" v-if="tag === 'exclusive_items'">⭐ Contains exclusive items</span>
            <span class="tag" v-if="tag === 'incomplete'">🔎 Incomplete</span>
            <span class="tag" v-if="tag === 'damaged'">🔨 Damaged</span>
            <span class="tag" v-if="tag === 'two'">2️⃣ Two-month spanning release</span>
            <span class="tag" v-if="tag === 'misprint'">🤪 Misprint</span>
        </span>
      </template>
    </div>
</template>

<script>
export default {
    name: "tags",
    props: {
        tags: {type: Array},
        show_description: {type: Boolean, default: false}
    }
}
</script>

