<style>
.b-table-sticky-header {
  margin-bottom: 0 !important;
  /* Prevent the page from ever scrolling: the nav and table fit on a single page in any situation this way. */
  min-height: calc(100vh - 55px);
}

.b-table-sticky-column {
  font-weight: bold;
  text-align: right;
  min-width: 200px;
  padding-left: 2px;
}

.nav {
  height: 55px;
}

/* Both the top and column are 1 pixel off, so you can see the images past them. */
th {
  top: -1px !important;
}

.b-table-sticky-column {
  left: -1px !important;
}

</style>
<template>
  <div>
    <!-- Todo: move nav and filtering to main app, use vuex to set filter. -->
    <!-- Todo: allow filtering on tags. -->
    <b-navbar toggleable="lg" type="dark">

      <b-navbar-brand href="#">Online Piraterijmuseum</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-b-modal.about>
            <b-button variant="primary">Over</b-button>
          </b-nav-item>
          <b-nav-item v-b-modal.timeline>
            <b-button variant="info">Tijdslijn</b-button>
          </b-nav-item>
          <!-- <b-nav-item v-b-modal.trackcharts>
            <b-button variant="warning">Tracks</b-button>
          </b-nav-item> -->
          <b-nav-item v-b-modal.wantlist>
            <b-button variant="success">Wantlist</b-button>
          </b-nav-item>
        </b-navbar-nav>

        <b-nav-form @submit.stop.prevent="" class="ml-auto">

          <b-form-checkbox switch size="lg" style="margin-right: 1em; color: white;" v-model="filter_wanted">{{
              wanted
            }}
          </b-form-checkbox>
          <b-form-checkbox switch size="lg" style="margin-right: 1em; color: white;" v-model="filter_in_collection">
            {{ in_collection }}
          </b-form-checkbox>

          <b-input-group prepend="🔍">
            <b-form-input
                dark
                v-model="filter_text"
                type="search"
                id="filterInput"
                :placeholder="search_placeholder"
                debounce="100"
            ></b-form-input>
          </b-input-group>
        </b-nav-form>
      </b-collapse>

    </b-navbar>

    <youtubeplayer></youtubeplayer>

    <about :statistics="statistics"></about>
    <wantlist />
    <timeline />
    <!-- <track-charts /> -->

    <b-table
        small
        striped bordered dark foot-clone
        sticky-header
        clone-footer
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="normalized_database"
        :fields="fields"
        :filter-included-fields="filterOn"
        :filter="filter_text"
        :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2" style="min-height: 100vh;">
          <b-spinner class="align-middle"></b-spinner> &nbsp;
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(brand)="releases">
        {{ releases.item.brand }} <br>{{ releases.item.year }}
      </template>

      <template #cell(1)="releases">
                <span v-for="(tags, release_id) in releases['item']['1']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['1']).length"></release>
                </span>
      </template>
      <template #cell(2)="releases">
                <span v-for="(tags, release_id) in releases['item']['2']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['2']).length"></release>
                </span>
      </template>
      <template #cell(3)="releases">
                <span v-for="(tags, release_id) in releases['item']['3']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['3']).length"></release>
                </span>
      </template>
      <template #cell(4)="releases">
                <span v-for="(tags, release_id) in releases['item']['4']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['4']).length"></release>
                </span>
      </template>
      <template #cell(5)="releases">
                <span v-for="(tags, release_id) in releases['item']['5']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['5']).length"></release>
                </span>
      </template>
      <template #cell(6)="releases">
                <span v-for="(tags, release_id) in releases['item']['6']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['6']).length"></release>
                </span>
      </template>
      <template #cell(7)="releases">
                <span v-for="(tags, release_id) in releases['item']['7']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['7']).length"></release>
                </span>
      </template>
      <template #cell(8)="releases">
                <span v-for="(tags, release_id) in releases['item']['8']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['8']).length"></release>
                </span>
      </template>
      <template #cell(9)="releases">
                <span v-for="(tags, release_id) in releases['item']['9']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['9']).length"></release>
                </span>
      </template>
      <template #cell(10)="releases">
                <span v-for="(tags, release_id) in releases['item']['10']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['10']).length"></release>
                </span>
      </template>
      <template #cell(11)="releases">
                <span v-for="(tags, release_id) in releases['item']['11']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['11']).length"></release>
                </span>
      </template>
      <template #cell(12)="releases">
                <span v-for="(tags, release_id) in releases['item']['12']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['12']).length"></release>
                </span>
      </template>
      <template #cell(13)="releases">
                <span v-for="(tags, release_id) in releases['item']['13']" :key="release_id">
                    <release :id="release_id" :tags="tags" :visible="check_visibility(tags)"
                             :amount_this_month="Object.keys(releases['item']['13']).length"></release>
                </span>
      </template>
    </b-table>
    <settings/>
  </div>
</template>


<script>

import Wantlist from "@/components/wantlist";
import Timeline from "@/components/timeline";
// import TrackCharts from "@/components/TrackCharts";
import Settings from "@/components/settings";
const axios = require('axios').default;
import release from './release';
import about from './about';
import youtubeplayer from "@/components/youtubeplayer";

export default {
  components: {
    Settings,
    Wantlist,
    Timeline,
    // TrackCharts,
    release, about, youtubeplayer
  },
  mounted() {
    this.isBusy = true;
    axios.get('database/database.json').then(response => {
      this.database = response.data;
      this.normalize_database();
      this.publish_stats();
      this.isBusy = false;
    })
  },
  methods: {
    normalize_database: function () {
      // This adds duplication to the unique data so it's presentable in a table.
      let table_items = [];
      // todo: add menu to show 'Movie', 'Mp3', 'Software' as well in beta while there is no cover art.]=8560.
      let categories = ['Music'];


      categories.forEach((category) => {

        Object.entries(this.database[category]).forEach((brand) => {


          console.log(brand)
            Object.entries(this.database[category][brand[0]]).forEach((year) => {
              // console.log(year)
              table_items.push({
                'brand': brand[0],
                'year': year[0],
                '1': year[1][1],
                '2': year[1][2],
                '3': year[1][3],
                '4': year[1][4],
                '5': year[1][5],
                '6': year[1][6],
                '7': year[1][7],
                '8': year[1][8],
                '9': year[1][9],
                '10': year[1][10],
                '11': year[1][11],
                '12': year[1][12],
                '13': year[1][13],
              })
            })
        })
      })
      this.normalized_database = table_items;
    },

    publish_stats: function () {
      let months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
      this.normalized_database.forEach((row) => {
        // console.log(row);
        months.forEach((month) => {
          if (row[month] !== undefined) {
            Object.keys(row[month]).forEach((release_id) => {
              // release_id, tags = console.log(release_id, row[month][release_id]);
              this.statistics.releases += 1;

              if (!Object.keys(this.statistics.per_year).includes(row['year']))
                this.statistics.per_year[row['year']] = 1
              else
                this.statistics.per_year[row['year']] += 1

              if (!Object.keys(this.statistics.per_brand).includes(row['brand']))
                this.statistics.per_brand[row['brand']] = 1
              else
                this.statistics.per_brand[row['brand']] += 1

              if (release_id === 'missing_on_discogs') {
                this.statistics.missing_on_discogs += 1
              }

              if (release_id === 'missing_everywhere') {
                this.statistics.missing_everywhere += 1
              }

              if (row[month][release_id].includes('in_collection')) {
                this.statistics.in_collection += 1
              } else {
                this.statistics.wanted += 1

                if (!Object.keys(this.statistics.wanted_per_brand).includes(row['brand']))
                  this.statistics.wanted_per_brand[row['brand']] = 1
                else
                  this.statistics.wanted_per_brand[row['brand']] += 1

              }
            });
          }
        })
      })
      this.search_placeholder = `Search ${this.statistics.releases} releases`
      this.wanted = `Wanted (${this.statistics.wanted})`
      this.in_collection = `In Collection (${this.statistics.in_collection})`
    },

    check_visibility: function (tags) {
      // yes, duh!
      if (this.filter_in_collection && this.filter_wanted) {
        return true;
      }

      if (this.filter_in_collection) {
        return tags.includes('in_collection');
      }

      if (this.filter_wanted) {
        // inverse "in_collection"
        if (!tags.includes('in_collection'))
          return true;

        if (tags.includes('wanted'))
          return true;

        if (tags.includes('damaged'))
          return true;

        return tags.includes('incomplete');
      }
    }
  },

  watch: {},

  data() {
    return {
      statistics: {
        releases: 0,
        in_collection: 0,
        wanted: 0,
        per_brand: {},
        per_year: {},
        wanted_per_brand: {},
        missing_on_discogs: 0,
        missing_everywhere: 0,
      },

      search_placeholder: "Type to Search",
      wanted: "Wanted",
      in_collection: "In Collection",

      filterOn: ['brand', 'year'],
      filter_text: "",
      filter_in_collection: false,
      filter_wanted: false,
      isBusy: false,
      sortBy: 'year',
      sortDesc: false,
      fields: [
        {key: 'brand', sortable: true, stickyColumn: document.body.clientWidth > 800},
        {key: 'year', sortable: true},
        {key: '1', sortable: false, label: 'January'},
        {key: '2', sortable: false, label: 'February'},
        {key: '3', sortable: false, label: 'March'},
        {key: '4', sortable: false, label: 'April'},
        {key: '5', sortable: false, label: 'May'},
        {key: '6', sortable: false, label: 'June'},
        {key: '7', sortable: false, label: 'July'},
        {key: '8', sortable: false, label: 'August'},
        {key: '9', sortable: false, label: 'September'},
        {key: '10', sortable: false, label: 'October'},
        {key: '11', sortable: false, label: 'November'},
        {key: '12', sortable: false, label: 'December'},
        {key: '13', sortable: false, label: 'Various'},
      ],
      database: null,
      normalized_database: null,
    }
  }
}
</script>
