<style scoped>
.artist-image {
  margin: 2px;
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 20px;
  background-position: center;
  display: inline-block;
}

.image-container {
  width: 100%
}

.my_image {
  height: 200px;
  margin: 5px;
  border: 1px solid gray;
  border-radius: 4px;
}

.my_image:hover {
  border: 1px solid white;
}

h4 {
  margin-top: 0.8em;
  margin-bottom: 0.4em;
}
</style>
<style>
.tracklist td {
  vertical-align: middle !important;
}

.tracklist thead tr th:nth-of-type(1) {
  width: 50px;
}

.tracklist thead tr th:nth-of-type(2) {
  width: 50px;
}

</style>
<template>
  <!-- todo: support retrieving master release and then alternative versions. Retrieval should be done when creating the database. This is probably not needed, because they are allready in the same month. Just render the other items as well as releases in here. -->
  <div>
      <div style="clear: both; padding-bottom: 20px;">
        <tags :tags="tags" :show_description="true"/>
        <br>
      </div>
      <CoolLightBox
          :items="images"
          :index="index"
          :useZoomBar="true"
          :fullScreen="true"
          effect="swipe"
          overlayColor="rgba(20, 20, 20, .2)"
          @close="index = null">
      </CoolLightBox>

      <div class="image-container">
        <b-img
            class="my_image"
            v-for="(image, imageIndex) in images"
            :key="imageIndex"
            @click="index = imageIndex"
            :src="image"
        ></b-img>
      </div>

      <template v-if="info.identifiers">
        <h4>Identifiers</h4>
        <b-table striped hover bordered dark small
                 :items="info.identifiers"
                 :fields="identifiers_table">
        </b-table>
      </template>

      <template v-if="info.notes">
        <h4>Notes</h4>
        <p v-html="parsed_notes"></p>
      </template>

      <h4>Track listing</h4>
      <b-table striped hover bordered dark small class="tracklist" :items="info.tracklist" :fields="tracklist_fields">
        <template #cell(artists)="data">
                    <span v-for="artist in data.item.artists" :key="artist.name">
                        <div style="display: flex; align-items: center; ">
                            <span class="artist-image" :style="`background-image: url('${artist.thumbnail_url}')`">
                            </span> <span style="margin-left: 5px;">{{ artist.name }}</span>
                        </div>
                    </span>
        </template>
        <template #cell(play)="data">
          <template v-if="data.item.artists !== undefined">
            <a :href="`https://www.youtube.com/results?search_query=${create_youtube_search_query(data.item)}`"
               target="_blank" rel="nofollow">
              <b-icon icon="youtube" variant="danger" font-scale="3"></b-icon>
            </a>
          </template>
        </template>
      </b-table>

    </div>


</template>

<script>
const axios = require('axios').default;
import tags from './tags'

export default {
  components: {
    tags
  },
  name: "release_details",
  props: {
    id: {type: String},
    visible: {
      type: Boolean,
    },
    tags: {type: Array}
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    create_youtube_search_query: function (item) {
      let query = "";
      if (item.artists === undefined)
        return item.title;

      item.artists.forEach((artist) => {
        query += artist.name + " "
      });

      return `${query} - ${item.title}`
    }
  },
  data: function () {
    return {
      info: {},
      tracklist_fields: [
        {key: 'position', label: '#'},
        {key: 'play', label: 'Play'},
        {key: 'artists',},
        {key: 'title',},
      ],
      identifiers_table: [
        {key: 'type',},
        {key: 'value',},
        {key: 'description',},
      ],
      // Cool Lightbox:
      index: null,
    }
  },
  computed: {
    images: function () {
      if (this.info.images === undefined) {
        return []
      }

      let my_images = [];
      for (let i = 0; i < this.info.images.length; i++) {
        my_images.push(`database/${this.id}/${i}.webp`)
      }
      return my_images;
    },
    parsed_notes: function () {
      let my_notes = this.info.notes;
      my_notes = my_notes.replace(/\n/g, "<br>");
      return my_notes
    }
  },
  watch: {
    visible: function (new_value) {
      if (!new_value) return;
      axios.get(`database/${this.id}/release.json`).then(response => {
        this.info = response.data;
      })
    }
  },
}
</script>

