import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/styles.scss';
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        playlist: [],

        // support back button
        playlist_history: [],

        now_playing: {},
        // Time the track started playing, because of the duration we can calculate when the next track should play
        now_playing_started_at: 0,
        player_exclude_settings: {
            "style": {},
            "year": {},
            "genre": {},
            "country": {}
        }
    },

    mutations: {
        add_track_to_playlist(state, value) {
            state.playlist = value;
        },
        set_playing_track(state, value) {
            state.now_playing = value;
        },
        set_player_exclude_settings(state, params){
            state.player_exclude_settings[params[0]][params[1]] = params[2];
        }
    },

    plugins: [createPersistedState()],
});

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)

Vue.config.productionTip = false

// and ignore a genre
if(store.state.player_exclude_settings['genre']["folk, world, & country"] === undefined){
    store.commit("set_player_exclude_settings", ["genre", "folk, world, & country", true]);
}

// prevent schlager and other of such kind to be playing by default.
let initial_styles_to_ignore = ["schlager", "folk", "chanson", "levenslied", "spoken word", ""];
initial_styles_to_ignore.forEach(style => {
    if(store.state.player_exclude_settings['style'][style] === undefined){
        store.commit("set_player_exclude_settings", ["style", style, true]);
    }
})

// remove some years that do not make sense, and are likely incorrect finds
for(let year=1950; year < 1985; year++) {
    if (store.state.player_exclude_settings['year'][year] === undefined) {
        store.commit("set_player_exclude_settings", ["year", year, true]);
    }
}

// the music publications stop in 2010, ex some MP3 releases and the audio disk in masterbox.
for(let year=2011; year < 2030; year++) {
    if (store.state.player_exclude_settings['year'][year] === undefined) {
        store.commit("set_player_exclude_settings", ["year", year, true]);
    }
}



new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
