<style scoped>
.table td, .table th {
  padding: 0.25rem
}
</style>
<template>
  <b-table-simple table-variant="light" striped responsive hover v-if="database !== null">
      <b-thead>
      <b-tr>
        <b-th sticky-column>Month</b-th>
        <template v-for="year in years">
          <b-th v-for="month in months" :key="`${year}${month}`">
            {{ month }}
          </b-th>
        </template>
      </b-tr>
      <b-tr>
        <template>
          <b-th sticky-column style="min-width: 300px; background-color: white;">Brand / Year</b-th>
          <b-th v-for="year in years" colspan="13" :key="year" style="background-color: white;">
            {{ year }}
          </b-th>
        </template>
      </b-tr>

      </b-thead>
      <b-tbody>

      <b-tr v-for="brand in titles" :key="brand">
        <b-th sticky-column>{{ brand }}</b-th>

        <template v-if="Object.keys(database[databaseSection]).includes(brand)">
        <template v-for="year in years">
          <template v-for="month in months">

            <template
                v-if="Object.keys(database[databaseSection][brand]).includes(year) && Object.keys(database[databaseSection][brand][year]).includes(month)">
              <!-- why the random / why the duplicate keys? -->
              <td :key="`${year}${month}${brand}e${Math.random()}`" :style="month === '1' ? 'border-left: 1px solid var(--dark) !important' : ''"
                  style="background-color: var(--dark); text-align: center; color:silver">
                {{ Object.keys(database[databaseSection][brand][year][month]).length }}
              </td>
            </template>
            <template v-else>
              <td :key="`${year}${month}${brand}a${Math.random()}`" :style="month === '1' ? 'border-left: 1px solid var(--dark) !important' : ''">&nbsp;</td>
            </template>


          </template>
        </template>
        </template>
        <template v-else>
          <h2>{{brand}} not found </h2>
        </template>


      </b-tr>
      </b-tbody>
      <b-tfoot>

      <b-tr>
        <template>
          <b-th sticky-column style="min-width: 300px; background-color: white;"></b-th>
          <b-th v-for="year in years" colspan="13" :key="year" style="background-color: white;">
            {{ year }}
          </b-th>
        </template>
      </b-tr>
      </b-tfoot>
    </b-table-simple>
</template>

<script>
export default {
  name: "TimelineTable",

  props: {
    database: {type: Object, required: true},
    titles: {type: Array, required: true},
    years: {type: Array, required: true},
    databaseSection: {type: String, required: true},
  },

  data() {
    return {
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "8", "9", "10", "11", "12"],
    }
  }
}
</script>

<style scoped>

</style>