<style>
/* To remove the ugly edges of the modal boxes: */
.modal-content {
  background-color: transparent !important;
}
</style>

<template>
  <Collection/>
</template>

<script>
import Collection from './components/collection.vue'

export default {
  name: 'App',
  components: {
    Collection
  }
}
</script>

