<template>
  <b-sidebar
      id="sidebar-settings"
      title="Autoplay filters"
      backdrop-variant="dark"
      backdrop
      shadow

  >
    <div class="px-3 py-2">
      <b-alert show dismissable>Discogs classification may not be accurate or in good taste.</b-alert>

      <h3>Genres | <span @click="togglecategory('genres', true)">ON</span> / <span @click="togglecategory('genres', false)">OFF</span> </h3>
      <template v-for="(amount, genre) in genres">
        <enable-filter-button :amount="amount" :label="genre" :category="'genre'" :key="`genre_${genre}`" :refresh="refresh" />
      </template>

      <h3>Styles | <span @click="togglecategory('styles', true)">ON</span> / <span @click="togglecategory('styles', false)">OFF</span> </h3>
      <template v-for="(amount, genre) in styles">
        <enable-filter-button :amount="amount" :label="genre" :category="'style'" :key="`style_${genre}`" :refresh="refresh"  />
      </template>

      <h3>Years | <span @click="togglecategory('years', true)">ON</span> / <span @click="togglecategory('years', false)">OFF</span> </h3>
      <template v-for="(amount, genre) in years">
        <enable-filter-button :amount="amount" :label="genre" :category="'year'" :key="`year_${genre}`" :refresh="refresh" />
      </template>

      <h3>Countries | <span @click="togglecategory('countries', true)">ON</span> / <span @click="togglecategory('countries', false)">OFF</span> </h3>
      <template v-for="(amount, genre) in countries">
        <enable-filter-button :amount="amount" :label="genre" :category="'country'" :key="`country_${genre}`" :refresh="refresh" />
      </template>

    </div>
  </b-sidebar>
</template>

<script>
import {default as axios} from "axios";
import EnableFilterButton from "@/components/enableFilterButton";
export default {
  name: "settings",
  components: {EnableFilterButton},
  data() {
    return {
      genres: {},
      styles: {},
      years: {},
      countries: {},
      all: {},
      singular: {
        'genres': 'genre',
        'styles': 'style',
        'years': 'year',
        'countries': 'country'
      },
      // stupid hack to get the damn thing to update
      refresh: 1
    }
  },
  methods: {
    togglecategory(category, enable){
      let keys = Object.keys(this.all[category])

      keys.forEach(key => {
        // console.log(`Altering ${this.singular[category]} ${key} to ${enable}`)
        if (enable){
          this.$store.commit("set_player_exclude_settings", [this.singular[category], key, false]);
        } else {
          this.$store.commit("set_player_exclude_settings", [this.singular[category], key, true]);
        }
      })
      // affects all buttons down the lane, which have to reupdate.
      this.refresh += 1;
      this.$forceUpdate()
    }
  },
  mounted: function () {
    axios.get('track_filters.json').then(response => {
      this.all = response.data;
      this.genres = response.data.genres;
      this.styles = response.data.styles;
      this.years = response.data.years;
      this.countries = response.data.countries;
    });
  },
}
</script>

<style scoped>

</style>