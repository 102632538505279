<template>
  <b-table striped hover small
           :fields="fields" :items="converted_data" head-variant="dark" table-variant="dark"
           :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
  >

  </b-table>
</template>

<script>
export default {
  name: "GraphTable",
  props: {
    data: {type: Object, required: true},
  },

  data() {
    return {
      sortBy: 'value',
      sortDesc: true,
      fields: [
        {
          key: 'label',
          sortable: true,
          label: "Release Brand"
        },
        {
          key: 'value',
          sortable: true,
          label: "Number"
        },
      ]
    }
  },

  computed: {
    converted_data() {
      if (this.data === undefined)
        return []

      if (this.data.datasets === undefined)
        return []


      let values = this.data.datasets[0].data;
      let labels = this.data.labels;

      let objects = []
      for(let i=0; i<values.length;i++){
        objects.push({'label': labels[i], 'value': values[i]})
      }
      return objects
    }
  }
}
</script>

<style scoped>

</style>