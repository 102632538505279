<style>

</style>

<template>

  <b-modal ok-only id="about" size="xl" title="Over het piraterijmuseum" header-bg-variant="dark" header-text-variant="light"
           footer-bg-variant="dark" footer-text-variant="light" body-text-variant="light" body-bg-variant="dark"
           no-fade scrollable @shown="updateData()" @close="clearData()" @hidden="clearData()">
    <h3 slot="modal-title">Over het online piraterijmuseum</h3>
    <div slot="default">
      <p>Welkom bij het online piraterijmuseum. Op deze site kan je alle illegaal geproduceerde verzamelcd's van de jaren 90
        terugvinden.</p>
      <p>Dank aan alle auteurs op Discogs voor het samenstellen van deze gegevens. Deze gegevens worden een paar
      keer per jaar bijgewerkt. Mocht er hier iets missen: voeg het toe op discogs en laat het weten.</p>
      <p>Onze hoofdwebsite is: <a href="https://piraterijmuseum.nl" target="_blank">https://piraterijmuseum.nl</a></p>

      <h2>Donaties</h2>
      <p>
        Alles wat op deze pagina ontbreekt of blijft staan nadat je het 'wanted' schuifje hebt aangeklikt zoeken we nog.
        Bij de laatste update zijn dat nog minstens {{statistics.wanted}} uitgaven.
      </p>

      <p>Doneren kan op de volgende manieren:</p>
      <ul>
        <li>Overleg en foto's: stuur een mailtje naar <a href="mailto:stitch@awesomespace.nl">stitch@awesomespace.nl</a></li>
        <li>Opsturen: voor het adres, stuur een mailtje naar <a href="mailto:stitch@awesomespace.nl">stitch@awesomespace.nl</a></li>
        <li>Langsbrengen tijdens openingstijden: tijdens de <a href="http://awesomespace.nl/" target="_blank" rel="nofollow">openingstijden</a> kan je altijd een donatie langsbrengen</li>
        <li>Langsbrengen op afspraak: stuur een mailtje naar <a href="mailto:stitch@awesomespace.nl">stitch@awesomespace.nl</a> om een afspraak te maken</li>
        <li>Afhalen: in sommige gevallen kunnen we een collectie afhalen. Stuur een mailtje naar <a href="mailto:stitch@awesomespace.nl">stitch@awesomespace.nl</a> om een afspraak te maken.</li>
        <li>Donaties in geld worden omgezet in displays, presentatiemiddelen en upgrades aan het museum. Er worden geen illegale producties gekocht of verkocht.</li>
      </ul>

      <h2 class="mt-4">Releases per year</h2>
      <p>Some important milestones:</p>
      <ul>
        <li>End of 1999: Major arrests of the biggest producer of releases (MTV, Hit Explosion etc).</li>
        <li>June 1999: Release of Napster file sharing service, making it easy to find and share music online.</li>
        <li>May 2000: Limewire file sharing service was released, a major source for mp3 downloads.</li>
        <li>October 2001: The iPod was released.</li>
        <li>September 2003: The Pirate Bay was released</li>
      </ul>
      <bar-chart
          :chart-data="this.releases_per_year"
          :options="{height: '400px', position: 'relative', responsive: true, maintainAspectRatio: false}">
      </bar-chart>

      <h2 class="mt-4">Releases per brand</h2>
      <p>MTV Braun was by far the largest brand in absolute numbers. The creators of this brand had a wide portfolio
      of other releases: Serious Beats, Hit Explosion and many others. After 1999 the brand with the largest
      amount of releases was PPP, with the 4 box, new hitbox and others.</p>
      <graph-table :data="this.releases_per_brand"></graph-table>
      <bar-chart
          :chart-data="this.releases_per_brand"
          :options="{height: '700px', position: 'relative', responsive: true, maintainAspectRatio: false}">
      </bar-chart>

      <h2 class="mt-4">Wanted per brand</h2>
      <p>To give an idea how much we still need to create a full collection, as far as we know.</p>

      <graph-table :data="this.wanted_per_brand"></graph-table>

      <bar-chart
          :chart-data="this.wanted_per_brand"
          :options="{height: '700px', position: 'relative', responsive: true, maintainAspectRatio: false}">
      </bar-chart>




      <h2>More</h2>
      <p>The collection contains {{statistics.in_collection}} releases. There are {{statistics.wanted}} wanted releases.</p>
      <p>There are {{statistics.missing_on_discogs}} releases in collection but missing on discogs. There are {{statistics.missing_everywhere}}
      releases that exist which are not in collection NOR on discogs.</p>
      <ul>
        <li>In collection: {{statistics.in_collection}} </li>
        <li>Wanted: {{statistics.wanted}} </li>
        <li>Missing on discogs: {{statistics.missing_on_discogs}} </li>
        <li>Missing everywhere: {{statistics.missing_everywhere}} </li>
      </ul>
      <p>Copy Paste status: There  are {{statistics.missing_on_discogs}} releases in collection but missing on discogs. The entire collection now contains {{statistics.in_collection}} releases and we're still searching for at least {{statistics.wanted}} releases.</p>

    </div>
  </b-modal>

</template>

<script>
import BarChart from './barchart.js'
import GraphTable from './GraphTable'

export default {
  name: "about.vue",
  components: {
    BarChart,
    GraphTable
  },
  methods: {

    updateData: function () {
      this.releases_per_year = {
        labels: Object.keys(this.statistics.per_year),
        height: 300,
        datasets: [{
          height: 300,
          data: Object.values(this.statistics.per_year),
          label: "Releases per year",
          backgroundColor: "#FFFFFFCC",
          borderColor: "#FFFFFFFF",
          borderWidth: 1,
        }],
      };
      this.releases_per_brand = {
        labels: Object.keys(this.statistics.per_brand),
        height: 700,
        datasets: [{
          height: 700,
          data: Object.values(this.statistics.per_brand),
          label: "Releases per brand",
          backgroundColor: "#FFFFFFCC",
          borderColor: "#FFFFFFFF",
          borderWidth: 1,
        }],
      };
      this.wanted_per_brand = {
        labels: Object.keys(this.statistics.wanted_per_brand),
        height: 700,
        datasets: [{
          height: 700,
          data: Object.values(this.statistics.wanted_per_brand),
          label: "Wanted per brand",
          backgroundColor: "#FFFFFFCC",
          borderColor: "#FFFFFFFF",
          borderWidth: 1,
        }],
      };
      this.$forceUpdate();
    },

    clearData: function() {
      this.releases_per_year = {};
      this.releases_per_brand = {};
      this.wanted_per_brand = {};
    },
  },
  data: function () {
    return {
      releases_per_year: {},
      releases_per_brand: {},
      wanted_per_brand: {},
    }
  },
  props: {
    statistics: {type: Object}
  },
}
</script>