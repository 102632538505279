<template>
  <!-- Remove a small slice of the collection in order to make the UI easier to understand-->
  <b-button size="sm" :key="label" :variant="this.enabled ? this.enabledVariant : this.disabledVariant" class="m-1" @click="toggle">
    {{ label }} <b-badge variant="light">{{amount}}</b-badge>
  </b-button>
</template>

<script>

export default {
  name: "enableFilterButton",
  props: {
    enabledVariant: {type: String, default: "success"},
    disabledVariant: {type: String, default: "outline-success"},
    category: {type: String, default: ""},
    label: {type: String, default: ""},
    amount: {type: Number, default: 0},
    refresh: {type: Number, default: 0}
  },
  data() {
    return {
      enabled: true,
    }
  },
  mounted() {
    this.showRightState()
  },
  watch: {
    refresh: function(){
      this.showRightState()
    }
  },
  /**
   * true: disabled
   * undefined: enabled
   * false: enabled
   * */
  methods: {
    showRightState(){
      if (this.$store.state.player_exclude_settings[this.category][this.label] === true){
        this.enabled = false;
      } else {
        this.enabled = true;
      }
    },

    toggle: function (){
      console.log(`Toggling ${this.category} ${this.label}`)

      console.log(this.label)
      if (this.$store.state.player_exclude_settings[this.category][this.label] === true) {
        console.log(`Enabling ${this.category} ${this.label}`)
        this.$store.commit("set_player_exclude_settings", [this.category, this.label, false]);
        this.enabled = true;
      } else {
        console.log(`Disabled ${this.category} ${this.label}`)
        this.$store.commit("set_player_exclude_settings", [this.category, this.label, true]);
        this.enabled = false;
      }
    },
  },
}
</script>

<style scoped>

</style>