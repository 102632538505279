<style scoped>
.larger_player {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 418px;
  /* Put player always on top... */
  z-index: 2000;
  background-color: transparent;
  text-align: center;
  padding: 1em;
  border-radius: 4px;
}
.miniplayer{
  position: fixed;
  bottom: 0;
  right: 1em;
  width: 420px;
  padding: 0.3em;
  z-index:2000;
  background-color: red;
}

.youtube_frame {
  border-radius: 10px 10px 0 0;
}

.controls {
  width: 100%;
  background-color: black;
  text-align: center;
  padding: 0.4em;
  border-radius: 0 0 10px 10px;
  margin-top: -6px;
}

</style>
<template>
  <div>
    <div class="miniplayer" :style="visible !== 'miniplayer' ? 'visibility: hidden' : ''">
      <b-button @click="visible='larger_player'"><b-icon variant="light" icon="arrow-up-left-circle-fill"></b-icon></b-button> &nbsp;
      <b-button v-b-toggle.sidebar-settings><b-icon icon="gear" variant="light"></b-icon></b-button>&nbsp;
      <span style="color: white; font-family:courier; font-size: 1.4em;" v-if="this.visible_countdown_counter > 0">
        <span v-html="format_seconds_to_time(visible_countdown_counter)"></span>
      </span>
      &nbsp;
      <b-button @click="stop()"><b-icon icon="stop-btn" variant="light"></b-icon></b-button>&nbsp;
      <b-button @click="play()"><b-icon icon="play-btn" variant="light"></b-icon>️</b-button>&nbsp;
      <b-button @click="next()"><b-icon icon="skip-forward-btn" rotate="180" variant="light"></b-icon>️</b-button>&nbsp;
      <span v-if="this.queue.length > 0" style="color: white;"><b-icon variant="warning" icon="collection-play-fill"></b-icon> <span style="color: var(--warning)">{{ this.queue.length }}</span>&nbsp;
        <b-button @click="clear_queue()"><b-icon icon="trash-fill" variant="warning"></b-icon></b-button>
      </span>
    </div>
    <div class="larger_player" :style="visible !== 'larger_player' ? 'visibility: hidden' : ''">
      <div class="video" id="video">
        <iframe id='my_youtube_player' class="youtube_frame"
                width="386"
                height="315"
                src="https://www.youtube-nocookie.com/embed/r6FVk2k4qsM?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
        </iframe>
      </div>
      <div class="nowplaying">

      </div>
      <div class="playlist">

      </div>
      <div class="controls">
        <b-button variant="default" @click="visible='miniplayer'"><b-icon variant="light" icon="arrow-down-right-circle-fill"></b-icon></b-button> &nbsp;
        <b-button variant="warning"  v-b-toggle.sidebar-settings><b-icon icon="gear"/> </b-button>
        <span class="p-2" style="color: white; font-family:courier; font-size: 1.4em;" v-if="this.visible_countdown_counter > 0">
          <span v-html="format_seconds_to_time(visible_countdown_counter)"></span>
        </span>
        &nbsp;
        <b-button variant="danger" @click="stop()"><b-icon icon="stop-btn" variant="light"></b-icon></b-button>&nbsp;
      <b-button variant="success" @click="play()"><b-icon icon="play-btn" variant="light"></b-icon>️</b-button>&nbsp;
      <b-button variant="info" @click="next()"><b-icon icon="skip-forward-btn" rotate="180" variant="light"></b-icon>️</b-button>
        <span v-if="this.queue.length > 0" style="color: white;"><br />&nbsp; Queued: {{ this.queue.length }} tracks.
          <b-button variant="danger" @click="clear_queue()"><b-icon icon="trash-fill" variant="secondary"></b-icon></b-button>
        </span>
        <div v-if="this.queue.length > 0" style="overflow: scroll; max-height: 400px;">
          <img @click='play_queued_track(item)' :alt='item' :src="'player/thumbs/' + item + '.webp'" height="50"
               v-for="item in queue" :key="item" style="margin: 2px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

const axios = require('axios').default;

export default {
  name: "youtube_player",
  mounted: function () {
    this.youtube_player = document.getElementById('my_youtube_player');
    this.video_container = document.getElementById('video');

    axios.get('track_db_optimized.json').then(response => {
      this.track_db = response.data;
      this.track_keys = Object.keys(this.track_db);

      this.preview_track(this.get_random_track());
      // Note: the first track is _always_ loaded paused. This means it will shock people
      // when the next track is loaded.
    });
  },

  methods: {
    play: function () {
      // reset playing the track, if you pressed stop for example.
      this.play_track(this.currently_playing);
    },
    // https://stackoverflow.com/questions/55773602/how-do-i-create-a-simple-10-seconds-countdown-in-vue-js
    countDownTimer() {
      clearTimeout(this.visiblecountdowntimeout);
      if (this.visible_countdown_counter > 0) {
        this.visiblecountdowntimeout = setTimeout(() => {
          this.visible_countdown_counter -= 1;
          this.countDownTimer();
        }, 1000)
      }
    },
    play_queued_track: function (track_slug) {
      // remove item from the queue as it is played NOW!
      const index = this.queue.indexOf(track_slug);
      if (index > -1) {
        this.queue.splice(index, 1);
      }

      this.play_track(track_slug);
    },
    preview_track: function (track_slug) {
      // Load the clip, but do not start the countdown timer yet, and don't play it.
      // This prevents the shock of music playing after a few minutes when the current track countdown is done.
      this.currently_playing = track_slug;
      this.youtube_player.style.height = "";
      this.youtube_player.src = this.create_youtube_url(this.track_db[track_slug]['yt'], false);
    },
    play_track: function (track_slug) {
      console.log(`Now playing: ${track_slug}.`)
      let metadata = this.track_db[track_slug]['m'];
      console.log(`Track metadata: genres: ${metadata['g']}, styles: ${metadata['s']}, year: ${metadata['y']}, country: ${metadata['c']}`)
      this.currently_playing = track_slug;
      this.youtube_player.style.height = "";
      this.youtube_player.src = this.create_youtube_url(this.track_db[track_slug]['yt'], true);
      // when the track is done + 3 second lead it will trigger the next track.
      // this is done with a timeout based on 't' (duration) of the queued track according to youtube.
      this.set_autoplay_timer(this.track_db[track_slug]['t']);
    },
    get_random_track: function () {
      // some help to see if we can still play something. we should select from this list.
      // todo: playable can be computed, which saves some calculations probably. This should also be shared
      //  globally somehow so a playlist of resulted tracks can be shown.
      let playable = this.tracks_left()
      if (playable.length > 0) {
        // let found_key = this.track_keys[Math.floor(Math.random() * this.track_keys.length)];
        return playable[Math.floor(Math.random() * playable.length)];
      }
      // fallback if the selection was too narrow
      return this.track_keys[Math.floor(Math.random() * this.track_keys.length)]
    },
    tracks_left(){
      // apply filters to the entire playlist and see how many tracks are still left. Rotate over those?
      let tracks_that_can_be_played = [];
      this.track_keys.forEach(key => {
        if(this.track_complies_with_all_filters(key))
          tracks_that_can_be_played.push(key)
      })
      console.log(`Still able to play ${tracks_that_can_be_played.length} tracks.`)
      return tracks_that_can_be_played;
    },
    // todo: add _queue_ random track?
    track_complies_with_all_filters(track_key) {

      if (this.artist_is_cancelled(track_key))
        return false

      let metadata = this.track_db[track_key]['m'];

      // default metadata values result in useless stuff.
      if (metadata['y'] === "-1") {
        // console.log(`${track_key} fails for unknown year.`)
        return false
      }

      if (metadata['c'] === "unknown") {
        // console.log(`${track_key} fails for unknown country.`)
        return false
      }

      if (metadata['g'][0] === "unknown") {
        // console.log(`${track_key} fails for unknown genre.`)
        return false
      }

      if (metadata['s'][0] === "unknown") {
        // console.log(`${track_key} fails for unknown style.`)
        return false
      }

      if (!this.complies_with_year_filter(metadata['y'])) {
        // console.log(`${track_key} fails on year.`)
        return false
      }

      if (!this.complies_with_country_filter(metadata['c'])) {
        // console.log(`${track_key} fails on country.`)
        return false
      }

      // the bit more expensive filters.
      if (!this.complies_with_genre_filter(metadata['g'])) {
        // console.log(`${track_key} fails on genre.`)
        return false
      }

      if (!this.complies_with_style_filter(metadata['s'])) {
        // console.log(`${track_key} fails on style.`)
        return false
      }

      // can we do this with filters?
      // console.log(`${track_key} complies with all filters.`)
      return true
    },
    artist_is_cancelled(track_key) {
      this.cancelled_artists.forEach((cancelled_artist) => {
        if (track_key.indexOf(cancelled_artist) !== -1)
          return true;
      })
      return false;
    },
    complies_with_genre_filter(values){
      let blocked_genres = 0;
      values.forEach((value) => {
        if (this.$store.state.player_exclude_settings["genre"][value] === true){
          blocked_genres += 1
        }
      })

      return blocked_genres !== values.length;
    },
    complies_with_style_filter(values){
      // this is a bit slower, since it iterates over all values instead of stopping if there is one ok genre.
      // might be faster to add a filter function on complete list with a compare function like this/
      let blocked_genres = 0;
      values.forEach((value) => {
        if (this.$store.state.player_exclude_settings["style"][value] === true){
          blocked_genres += 1
        }
      })

      return blocked_genres !== values.length;
    },
    complies_with_year_filter(year){
      if (this.$store.state.player_exclude_settings["year"][year] === true) {
        return false;
      }
      return true
    },
    complies_with_country_filter(value){
      if (this.$store.state.player_exclude_settings["country"][value] === true) {
        return false;
      }
      return true
    },
    stop: function () {
      // todo: add placeholder image for player, instead of showing an empty url.
      this.youtube_player.src = '';
      this.youtube_player.style.height = "0px";
      clearTimeout(this.autoplay_timeout);
      this.visible_countdown_counter = 0;
    },
    next: function () {
      if (this.queue.length === 0) {
        this.play_track(this.get_random_track());
      } else {
        this.play_track(this.queue.shift());
      }
    },
    clear_queue: function () {
      this.queue = [];
    },
    format_seconds_to_time: function (seconds) {
      // https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const minutes = (seconds % 3600) / 60
      return [minutes, seconds % 60].map(format).join(':')
    },
    set_autoplay_timer: function (human_time_duration) {
      // human_time_duration is a string in the form of 4:12, mixes may take an hour or so (erroneous links)
      let seconds = 0;
      let a = human_time_duration.split(':');
      if ((human_time_duration.match(/:/g) || []).length === 2) {
        //  mixes may take an hour or so (erroneous links)
        seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      } else {
        seconds = (+a[0]) * 60 + (+a[1]);
      }
      if (seconds > 10) {
        console.log(`Will play the next song in ${seconds}.`);
        this.visible_countdown_counter = parseInt(seconds);
        this.countDownTimer();
        clearTimeout(this.autoplay_timeout);
        this.autoplay_timeout = setTimeout(() => {
          this.next()
        }, ((parseInt(seconds) + 2) * 1000));
      }
    },
    create_youtube_url: function (youtube_track_id, autoplay) {
      if(autoplay)
        return `https://www.youtube-nocookie.com/embed/${youtube_track_id}?autoplay=1`
      return `https://www.youtube-nocookie.com/embed/${youtube_track_id}?autoplay=0`
    }
  },
  data: function () {
    return {
      visible: "larger_player",

      state: 'paused',
      youtube_player: null,
      video_container: null,
      track_db: null,

      visible_countdown_counter: 0,
      visiblecountdowntimeout: null,
      currently_playing: '',
      autoplay_timeout: null,
      queue: [],

      // use the slug. This way at least someone will not get to hear a song of them randomly.
      // users _can_ on purpose click on a track of these artists (if they are in the catalog at all) to
      // hear these songs. Perhaps these will be autoskipped in the future just because.
      // No youtube revenue for you.
      cancelled_artists: ['borsato', 'ali_b_', 'lil_kleine']
    }
  },
  props: {
    tags: {type: Array},
    show_description: {type: Boolean, default: false}
  },
  watch: {
    // will be the slug of the item.
    now_playing: function (track_slug) {
      this.play_track(track_slug);
    },
    playlist: function (track_slugs) {
      track_slugs.forEach((track_slug) => {
        // prevent duplicate warning, it would be nice to have multiple times the same track
        if (!this.queue.includes(track_slug)) {
          this.queue.push(track_slug);
        }
      })
    }
  },
  computed: mapState(['now_playing', 'playlist', 'playlist_history']),
}
</script>

