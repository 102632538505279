<style scoped>
.disc {
  margin: 2px;
  background-size: cover;
  background-position: center;
  border: 1px solid gray;
  border-radius: 4px;
}

.disc1 {
  width: 150px;
  height: 150px;
}

.disc2, .disc3, .disc4, .disc5, .disc6 {
  width: 73px;
  height: 73px;
  float: left;
}

.disc:hover {
  border: 1px solid white;
  border-radius: 4px;
}

.disc-container {
  min-width: 154px;
}

.somewhatvisible {
  /* https://stackoverflow.com/questions/59470947/vue-cli-build-production-change-opacity-in-css */
  opacity: 0.2 !important;
   filter: grayscale(70%);
}

/*
todo: add missing artwork feature
.release_in_museum_fallback_image:before {
  background-image: url('missing_on_discogs.png');
}

.release_in_museum_fallback_image {
  background-image: url('missing_on_discogs.png');
}

*/


</style>
<style>
#modal-1, .cool-lightbox {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.wide-popover {
  min-width: 1000px;
  overflow: scroll !important;
  max-height: 600px;
}
</style>
<template>
  <div :class="visible ? 'disc-container' : 'disc-container somewhatvisible'">
    <div v-if="id === 'missing_everywhere'" :class="`disc disc${amount_this_month}`"
         style="background-image: url('missing_everywhere.png')">
      <tags :tags="tags"/>
    </div>

    <div v-if="id ==='missing_on_discogs'" :class="`disc disc${amount_this_month}`"
         style="background-image: url('missing_on_discogs.png')">
      <tags :tags="tags"/>
    </div>

    <div v-if="id ==='unknown'" :class="`disc disc1`" style="background-image: url('unknown.png')">
      <tags :tags="tags"/>
    </div>

    <div v-if="id ==='does_not_exist'" :class="`disc disc1`" style="background-image: url('does_not_exist.png')">
      <tags :tags="tags"/>
    </div>

    <div v-if="!['missing_on_discogs', 'missing_everywhere', 'unknown', 'does_not_exist'].includes(id)">
      <a @click="details_visible = true" target="_blank" rel="nofollow">
        <div :class="`disc disc${amount_this_month} release_in_museum_fallback_image`"
             :style="`background-image: url('database/${id}/0_thumbnail.webp'); background-color: lightgreen;`" :id="id">
          <tags :tags="tags"/>
        </div>
      </a>

      <release_details
          :id="id"
          :show="details_visible"
          :visible="details_visible"
          @close="details_visible = false"
          :tags="tags"
      ></release_details>

      <div v-if="$windowWidth > 800">
        <b-popover data-flip="false" data-display="static" custom-class="wide-popover" :delay=" { show: 1000, hide: 0 }" :target="id"
                   triggers="hover" placement="bottom" :fallback-placement="['bottom']"
                   :allowedAutoPlacements="['bottom']" variant="dark" @shown="show_preview=true"
                   @hide="show_preview=false">
          <template #title>Quick Preview of {{ id }}</template>
          <quick_preview
              :visible="show_preview"
              :id="id"
              :tags="tags"
          ></quick_preview>
        </b-popover>
      </div>

    </div>

  </div>
</template>

<script>
import tags from './tags'
import release_details from './release_details'
import quick_preview from './quick_preview'
// Highly, highly inefficient, but bootstrap doesn't work, so well..
import { vueWindowSizeMixin } from 'vue-window-size/option-api';

export default {
  components: {
    tags,
    release_details,
    quick_preview
  },
  mixins: [vueWindowSizeMixin()],
  name: "release",
  props: {
    id: {type: String},
    tags: {type: Array},
    visible: {type: Boolean, default: true},
    amount_this_month: {type: Number, default: 1},
  },
  data: function () {
    return {
      details_visible: false,
      show_preview: false,
    }
  }

}
</script>

<style scoped>

</style>