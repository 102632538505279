<style>
/*this cant be big enough*/
#timeline .modal-xl {
  max-width: 95vw !important;

}

</style>


<template>
  <b-modal ok-only id="timeline" size="xl" title="Tijdslijn" header-bg-variant="dark" header-text-variant="light"
           footer-bg-variant="dark" footer-text-variant="light" body-text-variant="light" body-bg-variant="dark"
           no-fade scrollable>
    <p>
      Dit is een overzicht van de langst lopende series over meerdere jaren. Hier zie je eenvoudig hoeveel jaar alle
      series hebben gelopen. Series met minder dan 10 releases of minder dan 3 jaar zijn niet meegenomen tenzij het
      (soort van) duidelijk is dat het gaat over een directe opvolger.
    </p>
    <b-tabs pills>

      <b-tab title="Muziek (CD) | 1992 - 2006">
        <timeline-table :years="cdYears" :titles="cdBrandOrder" :database-section="'Music'" :database="database"/>
      </b-tab>

      <b-tab title="Muziek (Tape) | 1984 - 2000">
        <timeline-table :years="tapeYears" :titles="tapeBrandOrder" :database-section="'MusicTape'"
                        :database="database"/>
      </b-tab>

      <b-tab title="Muziek (MP3 DVD) | 2001 - 2010">
        <timeline-table :years="mp3Years" :titles="mp3BrandOrder" :database-section="'Mp3'" :database="database"/>
      </b-tab>

      <b-tab title="Films (DVD boxsets) | 2006 - 2018">
        <timeline-table :years="movieYears" :titles="movieBrandOrder" :database-section="'Movie'" :database="database"/>
      </b-tab>

      <b-tab title="Software | 1995 - 2007">
        <timeline-table :years="softwareYears" :titles="softwareBrandOrder" database-section="Software"
                        :database="database"/>
      </b-tab>

    </b-tabs>
  </b-modal>
</template>


<script>

import TimelineTable from "@/components/TimelineTable";

const axios = require('axios').default;

export default {
  components: {TimelineTable},
  mounted() {
    this.isBusy = true;
    axios.get('database/database.json').then(response => {
      this.database = response.data;

      this.isBusy = false;
    })
  },

  data() {
    return {

      cdYears: ["1992", "1993", "1994", "1995", "1996", "1997", "1998", "1999", "2000", "2001", "2002", "2003", "2004", "2005", "2006"],
      // this order takes the "when was this first released into account" So there is a nice waterfall effect on the timeline
      // we can do this with some calculations but that makes the code even more complicated.
      // given this is a finite collection, it makes sense to limit this
      // This can also exclude one-off productions, which just add a line of 1 release which doesn't add a lot of value or insights
      // we can also sort them a bit on production group
      // only multi-year series
      cdBrandOrder: [
        "Chartbusters", "Nieuwe Top 40 Hits", "MTV Braun Eurochart", "De Beste van de Top 50", "Mega Hits Top 50",
        // Zwolle
        "Clubtracks", "Club House",
        // Het beste + concurrenten
        "Het Beste Uit De Enige Echte Mega Top 50-100 (DOR)",
        "Het Beste Uit De Top 100 Megacharts (LMR)",
        "Het Beste Uit De Enige Echte Mega Top 50-100 (kuiken)",
        // Bulls is probably from mister x
        "Het Beste Uit De Enige Echte Mega Top 50-100 (bulls)",
        // Mister X
        "Hit Explosion", "Serious Hits", "Maxi Music", "TMF Music Factory", "Veronica / Yorin", "Airplay Top Charts", "Music Planet", "The Box",
        // 3FM + vervolg
        "3FM Megahits", "Euro Mega Hits",
        // PPP
        "Hit Box", "The 4 Box / Club Dance Charts",
        // anderen
        "€urobox", "Hit Break"
      ],

      movieYears: ["2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
      movieBrandOrder: [
        "Masterbox", "Moviebox", "Voordeelbox", "Blockbuster", "Filmbox"
      ],


      softwareYears: ["1995", "1996", "1997", "1998", "1999", "2000", "2001", "2002", "2003", "2004", "2005",
        "2006", "2007"],
      softwareBrandOrder: [
        "Crazy Bytes",
        "Oh No More Games",
        // started in 95
        "Winwarez Pro",
        "Private Domain",
        "Phantasm",
        "Shitware",
        "Pleasure Dome",
        "Winzippers",
        // started in 96, as far as the database knows...

        "Digital Stealth",
        "Pro Dreams",
        "One Off - eenmalige uitgaven",
        "The Legacy",
        "Hollandse Bits",
        "Twilight",

        // 1997
        "From holland with love",
        "Winfiles",
        "Storm",
        "The Prodigy",
        "ABC Warez",
        "Megapack",
        "Happy Bytes",

        // 98
        "Bits & Bytes",

        // 99
        "Super Files",

        // 03
        "Onyx DVD",
      ],

      tapeYears: ["1986", "1987", "1988", "1989", "1990", "1991", "1992", "1993", "1994", "1995", "1996", "1997", "1998", "1999", "2000"],
      tapeBrandOrder: [
        "Het beste uit de top 40 (palmboom/studiowestland)",
        "Het beste uit de top 40 (vierkant nummer)"
      ],

      mp3Years: ["2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010"],
      mp3BrandOrder: [
        "X-Plosion MP3 Update", "Onyx MP3 DVD", "MP3 Collection"
      ],

      database: null,
    }
  },
}
</script>
