<style scoped>
.artist-image {
  margin: 2px;
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 20px;
  background-position: center;
  display: inline-block;
}

.image-container {
  width: 100%
}

.my_image {
  height: 200px;
  margin: 5px;
  border: 1px solid gray;
  border-radius: 4px;
}

.my_image:hover {
  border: 1px solid white;
}

.card {
  float: left;
}

h4 {
  margin-top: 0.8em;
  margin-bottom: 0.4em;
}
</style>
<style>
.tracklist td {
  vertical-align: middle !important;
}

.tracklist thead tr th:nth-of-type(1) {
  width: 50px;
}

.tracklist thead tr th:nth-of-type(2) {
  width: 50px;
}

</style>
<template>
  <!-- todo: support retrieving master release and then alternative versions. Retrieval should be done when creating the database. This is probably not needed, because they are allready in the same month. Just render the other items as well as releases in here. -->
  <b-modal size="xl" :visible="visible" v-if="info.id" id="modal-1" title="Release Details" header-bg-variant="dark"
           header-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" body-text-variant="light"
           body-bg-variant="dark" scrollable @hidden="close()">
    <h3 slot="modal-title">{{ info.title }} ({{ info.id }})</h3>
    <div slot="modal-header-close">
      <b-button size="sm" variant="secondary" @click="close()">Close</b-button>
    </div>
    <div slot="default">
      <div style="clear: both; padding-bottom: 20px;">
        <tags :tags="tags" :show_description="true"/>
        <br>
      </div>
      <CoolLightBox
          :items="images"
          :index="index"
          :useZoomBar="true"
          :fullScreen="true"
          effect="swipe"
          overlayColor="rgba(20, 20, 20, .2)"
          @close="index = null">
      </CoolLightBox>

      <div class="image-container">
        <b-img
            class="my_image"
            v-for="(image, imageIndex) in images"
            :key="imageIndex"
            @click="index = imageIndex"
            :src="image"
        ></b-img>
      </div>

      <template v-if="info.identifiers.length > 0">
        <h4>Identifiers</h4>
        <b-table striped hover bordered dark small
                 :items="info.identifiers"
                 :fields="identifiers_table">
        </b-table>
      </template>

      <template v-if="info.notes">
        <h4>Notes</h4>
        <p v-html="parsed_notes"></p>
      </template>

      <!--
      <h4>Tracks as cards</h4>

        <b-card v-for="track in info.tracklist" :key="track.title"
          :title="track.title"
          :img-src="'player/thumbs/' + slugify(track) + '.webp'"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem;"
          class="mb-2"
          @click="play(track)"
          bg-variant="secondary" text-variant="light"
        >
          <b-card-text>

              <span v-for="artist in track.artists" :key="artist.name">
                  <div style="display: flex; align-items: center; ">
                      <span class="artist-image" :style="`background-image: url('${artist.thumbnail_url}')`">
                      </span> <span style="margin-left: 5px;">{{ artist.name }}</span>
                  </div>
              </span>
          </b-card-text>
      </b-card>

    -->


      <h4>Track listing</h4>

       <b-button variant="danger" class="m-2" @click="queue_all()"><b-icon icon="youtube" variant="light"></b-icon> Queue All</b-button>

      <b-table striped hover bordered dark small class="tracklist" :items="info.tracklist" :fields="tracklist_fields">
        <template #cell(artists)="data">
          <span v-for="artist in data.item.artists" :key="artist.name">
            <div style="display: flex; align-items: center; ">
              <span class="artist-image" :style="`background-image: url('${artist.thumbnail_url}')`">
              </span> <span style="margin-left: 5px;">{{ artist.name }}</span>
            </div>
          </span>
        </template>

        <template #cell(play)="data">
          <span v-if="data.item.artists !== undefined" style="white-space: nowrap;">
            <b-icon icon="youtube" variant="danger" font-scale="2" @click="play(data.item)"></b-icon>
            <b-icon icon="plus" variant="danger" font-scale="2" @click="queue(data.item)"></b-icon>
          </span>
        </template>

        <template #cell(title)="data">
          <img :src="'player/thumbs/' + slugify(data.item) + '.webp'" height="40"> {{ data.item.title }}
        </template>

      </b-table>

      <h4>Copyright</h4>
      <p>Information about this release is created using data from the following sources:</p>
      <ul>
        <li>Discogs & contributors: Track Listing, Artist Images, Notes, Scans</li>
        <li>YouTube & contributors: Video preview images, Embedded Video's withs Ads</li>
      </ul>
      <p></p>

    </div>
    <div slot="modal-footer">
      <b-button size="sm" variant="secondary" @click="close()">
        Close
      </b-button>
    </div>
  </b-modal>

</template>

<script>
const axios = require('axios').default;
import tags from './tags'

export default {
  components: {
    tags
  },
  name: "release_details",
  props: {
    id: {type: String},
    visible: {
      type: Boolean,
    },
    tags: {type: Array}
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    queue_all: function(){
      let tracks = [];
      this.info.tracklist.forEach((track) => {
        tracks.push(this.slugify(track));
      })
      this.$store.commit("add_track_to_playlist", tracks);
    },
    play: function (item) {
      let track = this.slugify(item);
      this.$store.commit("set_playing_track", track);
    },
    queue: function (item) {
      let track = this.slugify(item);
      this.$store.commit("add_track_to_playlist", [track]);
    },
    slugify: function (item) {
      // first make the string as it should be
      let query = "";
      if (item.artists === undefined)
        return item.title;

      item.artists.forEach((artist) => {
        query += artist.name + " "
      });

      query += item.title

      // now slugify it
      // https://lucidar.me/en/web-dev/how-to-slugify-a-string-in-javascript/
      query = query.replace(/^\s+|\s+$/g, '');

      // Make the string lowercase
      query = query.toLowerCase();

      // regex chars
      query = query.replace(/\./g, '-');
      query = query.replace(/\?/g, '');
      query = query.replace(/!/g, '-');
      query = query.trim();

      // Remove accents, swap ñ for n, etc
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa&'·/_,:;";
      var to =   "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa--------";
      for (var i = 0, l = from.length; i < l; i++) {
        query = query.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      // Remove invalid chars
      query = query.replace(/[^a-z0-9 -]/g, '')
          // Collapse whitespace and replace by -
          .replace(/\s+/g, '-')
          // Collapse dashes
          .replace(/-+/g, '-');

      // remove - before and end:
      // https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
      query = query.replace(/^-+|-+$/g, '');


      return query;
    },
    create_youtube_search_query: function (item) {
      let query = "";
      if (item.artists === undefined)
        return item.title;

      item.artists.forEach((artist) => {
        query += artist.name + " "
      });

      return `${query} - ${item.title}`
    }
  },
  data: function () {
    return {
      info: {},
      tracklist_fields: [
        {key: 'position', label: '#'},
        {key: 'play', label: 'Play / Q'},
        {key: 'artists',},
        {key: 'title',},
      ],
      identifiers_table: [
        {key: 'type',},
        {key: 'value',},
        {key: 'description',},
      ],
      // Cool Lightbox:
      index: null,
    }
  },
  computed: {
    images: function () {
      if (this.info.images === undefined) {
        return []
      }

      let my_images = [];
      for (let i = 0; i < this.info.images.length; i++) {
        my_images.push(`database/${this.id}/${i}.webp`)
      }
      return my_images;
    },
    parsed_notes: function () {
      let my_notes = this.info.notes;
      my_notes = my_notes.replace(/\n/g, "<br>");
      return my_notes
    }
  },
  watch: {
    visible: function (new_value) {
      if (!new_value) return;
      axios.get(`database/${this.id}/release.json`).then(response => {
        this.info = response.data;
      })
    }
  },
}
</script>

